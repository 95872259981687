<template>
  <loading v-if="overlay_loading" />
  <div v-else class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <b-col
        offset-lg="6"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold store-info" title-tag="h2">
            {{ $t("g.login") }}
          </b-card-title>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group :label="$t('g.email')" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form_data.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    :placeholder="$t('g.email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group :label="$t('g.password')">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form_data.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between mt-1">
                  <b-link :to="{ name: 'forget-password' }">
                    <small>{{ $t("g.forgot_password?") }}</small>
                  </b-link>
                </div>
              </b-form-group>

              <b-button type="submit" variant="primary" block>
                {{ $t("g.login") }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="mt-2 account">
            <span>{{ $t("g.new_on_our_store?") }} </span>
            <b-link :to="{ name: 'signup' }">
              <span style="font-size: 15px">&nbsp;{{ $t("g.signup") }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import toast from "@/libs/toastification";
import loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    loading,
    OverlayComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      overlay_loading: false,
      form_data: {
        password: "",
        email: "",
      },
      status: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logoImg: require("@/assets/images/avatars/1-small.png"),
      required,
      email,
      isPending: false,
      instagram: require("@/assets/images/auth/instagram.svg"),
      apple: require("@/assets/images/auth/apple.svg"),
      google: require("@/assets/images/auth/google.svg"),
      twitter: require("@/assets/images/auth/twitter.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.overlay_loading = true;
          this.isPending = true;
          const formData = new FormData();
          formData.append("email", this.form_data.email);
          formData.append("password", this.form_data.password);
          if (localStorage.getItem("deviceToken")) {
            formData.append(
              "device_token",
              localStorage.getItem("deviceToken")
            );
          }
          this.submitHandler(formData);
        }
      });
    },
    async submitHandler(formData) {
      try {
        const req = await this.$http.post("auth/login", formData);
        if (req.status === 200) {
          const userData = req.data.data;
          useJwt.setToken(userData.token);
          await this.$store.dispatch("FETCH_DATA_USER");
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `You have successfully logged in - welcome back`,
            },
          });
          if (userData.user_vendor) {
            if (userData.user_vendor.vendor.is_approved) {
              setTimeout(() => {
                this.$router.push({ name: "home" });
              }, 300);
            } else {
              setTimeout(() => {
                this.$router.push({ name: "vendor-pending" });
              }, 300);
            }
          } else {
            await this.$router.push({ name: "create-new-vendor" });
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isPending = false;
        this.overlay_loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./login.scss";
</style>
